import React, { useState, useEffect } from "react";
import Aux from "../../../../hoc/_Aux";
import { stickyHeaderStyles } from "../../../../utils/constants/styleConstants";
import logo from "../../../../assets/images/assesshub logo green dot.svg";
import {gettrialOrgImageAPI} from '../../../../service/profileApiService'
import { Col, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { logout } from '../../../../service/dataService'
import { useProSidebar } from "react-pro-sidebar";
import { changeTheme } from "../../../../service/profileApiService";
import { profileMenu, themeNames } from "./navbarConstants";
//import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation , useNavigate } from 'react-router-dom';

const NewNavbar = ({ props }) => {
    const navigate = useNavigate();
    const uselocation = useLocation();

    const { collapsed, collapseSidebar } = useProSidebar();
    const [theme, setTheme] = useState(localStorage.getItem("themeColor") || "primary")
    const [hoveringOnModule, setHoveringOnModule] = useState();
    const [introStart, setIntroStart] = useState(localStorage.getItem("introStart") === "true");
    const [introStartCurrent, setIntroStartCurrent] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [lightOn, setLightOn] = useState(true);
    const [logoLink, setLogoLink] = useState(() => {
        const storedLogo = JSON.parse(localStorage.getItem("logo"));
        return storedLogo !== null ? storedLogo : logo;
    });
    const subMenuItemStyle = {
        height: "35px",
        paddingTop: "2px",
        paddingBottom: "2px",
        marginTop: "2px",
        marginBottom: "2px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        lineHeight: "30px",
        cursor: "pointer",
    }

    const fontColor = "#484848"

    setInterval(() => {
        if (themeNames.includes(localStorage.getItem('themeColor')) && localStorage.getItem('themeColor') != theme) {
            setTheme(localStorage.getItem('themeColor'))
        }
    }, 500);

    //Complete Intro Switch Code
    // const toggleIntroStart = () => {
    //     const newIntroStart = !introStart;
    //     localStorage.setItem("isSideBarCollapsed", "false");
    //     localStorage.setItem("introStart", newIntroStart);
    //     setIntroStart(newIntroStart);

    //     Object.keys(localStorage).forEach(key => {
    //         if (key.startsWith("introCompleted")) {
    //             localStorage.setItem(key, "false");
    //         }
    //     });

    //     if (window.location.hash.includes("/es/")) {
    //         navigate("/es/configure/questions");
    //         localStorage.setItem("selectedModule", "Engagement Survey");
    //         localStorage.setItem("selectedSubModule", "ES - Configure");
    //         window.location.reload();
    //     }
    //     else if (window.location.hash.includes("/adc/")) {
    //         navigate("/adc/configure");
    //         localStorage.setItem("selectedModule", "Assessment Centre");
    //         localStorage.setItem("selectedSubModule", "ADC - Configure");
    //         window.location.reload();
    //     }
    //     else if (window.location.hash.includes("/fb/")) {
    //         navigate("/fb/configure/competencymaster");
    //         localStorage.setItem("selectedModule", "Feedback Survey");
    //         localStorage.setItem("selectedSubModule", "FB - Configure");
    //         window.location.reload();
    //     }
    //     else {
    //         navigate("/group");
    //         localStorage.setItem("selectedModule", "Online Tests");
    //         localStorage.setItem("selectedSubModule", "OT - Positions");
    //         window.location.reload();
    //     }
    // };

    const toggleIntroStart = () => {
        if (window.location.hash.includes("/translate") || window.location.hash.includes("/translatedquestions") || window.location.hash.includes("/be") || window.location.hash.includes("/bot-api")) {
            toast.error("Intro is not avalable for this module");
            setIntroStart(false);
            setPopoverOpen(false);
        }
        else if (!introStartCurrent) {
            localStorage.setItem("isSideBarCollapsed", "false");
            setPopoverOpen(false);
            const newIntroStart = !introStart;
            setIntroStart(newIntroStart);
            localStorage.setItem("introStart", JSON.stringify(newIntroStart));

            // Remove introCompleted items
            Object.keys(localStorage).forEach((key) => {
              if (key.startsWith("introCompleted")) {
                localStorage.removeItem(key);
              }
            });

            // Set selected module and submodule based on URL
            let selectedModule, selectedSubModule, redirectPath;

            if (window.location.hash.includes("/es/")) {
                selectedModule = "Engagement Survey";
                selectedSubModule = "ES - Configure";
                redirectPath = "/es/configure/skillarea";
            } else if (window.location.hash.includes("/adc/")) {
                selectedModule = "Assessment Centre";
                selectedSubModule = "ADC - Configure";
                redirectPath = "/adc/configure";
            } else if (window.location.hash.includes("/fb/")) {
                selectedModule = "Feedback Survey";
                selectedSubModule = "FB - Configure";
                redirectPath = "/fb/configure/competencymaster";
            } else {
                selectedModule = "Online Tests";
                selectedSubModule = "OT - Skill Areas";
                redirectPath = "/skill-area";
            }

            localStorage.setItem("selectedModule", selectedModule);
            localStorage.setItem("selectedSubModule", selectedSubModule);

            // Update history and sidebar without reloading
            navigate(redirectPath);
        }
    };

    
    // const renderTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         <div className="d-flex flex-column align-items-center">
    //             <span className="tooltip-title mb-2" style={{ color: '#ffffff' }}>
    //                 Intro Guide
    //             </span>
    //             <div className="d-flex flex-column align-items-start mb-2">
    //                 <span className="tooltip-title mb-1" style={{ color: '#ffffff' }}>
    //                     Current Page
    //                 </span>
    //                 <div className="d-flex justify-content-center">
    //                     <Switch
    //                         checked={introStartCurrent}
    //                         onChange={toggleIntroStartCurrent}
    //                         onColor="#0B9E43"
    //                         offColor="#DD2C00"
    //                         onHandleColor="#ffffff"
    //                         offHandleColor="#ffffff"
    //                         handleDiameter={10}
    //                         uncheckedIcon={false}
    //                         checkedIcon={false}
    //                         height={15}
    //                         width={30}
    //                         className="react-switch"
    //                         id="intro-switch-current"
    //                     />
    //                 </div>
    //             </div>

    //             <div className="d-flex flex-column align-items-start">
    //                 <span className="tooltip-title mb-1" style={{ color: '#ffffff' }}>
    //                     Current Module
    //                 </span>
    //                 <div className="d-flex justify-content-center">
    //                     <Switch
    //                         checked={introStart}
    //                         onChange={toggleIntroStart}
    //                         onColor="#0B9E43"
    //                         offColor="#DD2C00"
    //                         onHandleColor="#ffffff"
    //                         offHandleColor="#ffffff"
    //                         handleDiameter={10}
    //                         uncheckedIcon={false}
    //                         checkedIcon={false}
    //                         height={15}
    //                         width={30}
    //                         className="react-switch"
    //                         id="intro-switch"
    //                     />
    //                 </div>
    //             </div>
    //         </div>
    //     </Tooltip>
    // );

    // Listen for route changes and stop the intro if running
    useEffect(() => {
        // This effect will run every time the location changes
        if (introStartCurrent) {
          localStorage.setItem("introStart", "false");
          setIntroStartCurrent(false);
        }
    
        // Cleanup logic (if necessary)
        return () => {
          // Any cleanup logic can be placed here, if needed
        };
      }, [uselocation, introStartCurrent]);

    //Current Intro Switch Code
    const toggleIntroStartCurrent = () => {

        setPopoverOpen(false);
        // Remove introCompleted items
        Object.keys(localStorage).forEach((key) => {
          if (key.startsWith("introCompleted")) {
            localStorage.removeItem(key);
          }
        });

        if (window.location.hash.includes("/ahdetailrpt") || window.location.hash.includes("/ahpersonalizedrpt") || window.location.hash.includes("/be") || window.location.hash.includes("/bot-api")) {
            toast.error("Intro is not avalable for this module");
            setIntroStartCurrent(false);
        }
        else if (!introStartCurrent) {
            localStorage.setItem("introStart", "true");
            setIntroStartCurrent(true);
        }
    };

    const togglePopover = () => {
        setPopoverOpen(!popoverOpen);
    };

    return (
        <Aux>
            <div
                style={{
                    ...stickyHeaderStyles,
                    top: 0,
                    height: "61px",
                    width: "100%",
                    borderBottom: "1px solid #E8E8E8",
                    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                    padding: "20px 3px",
                    zIndex: 99,
                }}
            >
                <Row style={{ margin: 0 }}>
                    <Col>
                        {window.innerWidth < 768 ?
                            <i
                                class="fa fa-bars"
                                aria-hidden="true"
                                style={{ fontSize: "20px", minWidth: "40px", marginLeft: "5px" }}
                                onClick={() => {
                                    localStorage.setItem("isSideBarCollapsed", !collapsed);
                                    collapseSidebar(!collapsed);
                                }}
                            ></i>
                            : null
                        }
                        <img
                            id="main-logo" src={logoLink} alt=""
                            style={{
                                width: "120px",
                                height: "30px",
                                marginTop: "-5px",
                                transition: "all 0.5s ease",
                                visibility: localStorage.getItem("isSideBarCollapsed") == "true" ? "visible" : "hidden",
                                opacity: localStorage.getItem("isSideBarCollapsed") == "true" ? "1" : "0"
                            }}
                            className="img img-responsive"
                        />
                    </Col>


                    <Col xs="auto" style={{ paddingRight: 0 }}>
                        <OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="bottom"
                            overlay={
                                <Popover style={{ padding: "10px", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)", }}>
                                    <Popover.Header as="h6">Choose Theme Color</Popover.Header>
                                    <Popover.Body>
                                        <Row style={{ margin: 0 }}>
                                            {themeNames.map(colorName =>
                                                <Col style={{ padding: "5px 5px" }}>
                                                    <div
                                                        className={`bg-${colorName} d-flex align-items-center justify-content-center growOnHover`} style={{ height: "25px", width: "25px", cursor: "pointer", borderRadius: "50%" }}
                                                        onClick={() => {
                                                            changeTheme({
                                                                field_name: "THEME",
                                                                field_details: colorName,
                                                                order_no: "0",
                                                                created_on: Date.now(),
                                                                // created_by: userId,
                                                            }).then((result) => {
                                                                localStorage.setItem("themeColor", colorName)
                                                                setTheme(colorName)
                                                            })
                                                        }}
                                                    >
                                                        {theme == colorName ?
                                                            <i className="icon feather icon-check f-w-800" style={{ fontSize: "18px", color: "#fff" }}></i>
                                                            : null
                                                        }
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                class="fas fa-circle"
                                aria-hidden="true"
                                style={{ fontSize: "20px", borderRadius: "100%", backgroundImage: "conic-gradient(from 90deg, violet, indigo, blue, green, yellow, orange, red)", color: "transparent", marginRight: "20px" }}
                            ></i>
                        </OverlayTrigger>
                        {/* Introduction Tour Guide */}

                        {lightOn && (
                            <OverlayTrigger
                                rootClose
                                show={popoverOpen}
                                onToggle={togglePopover}
                                placement="bottom"
                                overlay={<Popover style={{ padding: "10px", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)", }}>
                                    <Popover.Header as="h6">Quick Start Guide</Popover.Header>
                                    <Popover.Body>
                                        <Row style={{ margin: 0 }}>
                                            <div className="d-flex flex-column align-items-center">
                                                <div className="d-flex flex-column align-items-start mb-2">
                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            className={`btn btn-sm rounded-pill ${introStartCurrent ? 'btn-success' : 'btn-info'}`}
                                                            onClick={toggleIntroStartCurrent}
                                                            id="intro-button-current"
                                                            style={{
                                                                width: '110px',
                                                                height: '30px',
                                                                fontSize: '12px',
                                                                marginLeft: '13px',
                                                                position: 'relative',
                                                                paddingRight: '10px',
                                                            }}
                                                        >
                                                            <i className="icon feather icon-play" style={{ marginRight: "5px" }}></i>
                                                            Section
                                                            {/* <i className="fas fa-arrow-right" style={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)' }}></i> */}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-column align-items-start">
                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            className={`btn btn-sm rounded-pill ${introStart ? 'btn-success' : 'btn-danger'}`}
                                                            onClick={toggleIntroStart}
                                                            id="intro-button-current"
                                                            style={{
                                                                width: '110px',
                                                                height: '30px',
                                                                fontSize: '12px',
                                                                marginLeft: '13px',
                                                                position: 'relative',
                                                                paddingRight: '10px',
                                                                backgroundColor: '#b7348b',
                                                                borderColor: '#b7348b',
                                                                color: '#ffffff',
                                                            }}
                                                        >
                                                            <i className="icon feather icon-play" style={{ marginRight: "5px" }}></i>
                                                            Module
                                                            {/* <i className="fas fa-arrow-right" style={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)' }}></i> */}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </Popover.Body>
                                </Popover>}
                                trigger="click"
                            >
                                <i
                                    className="fa fa-lightbulb"
                                    aria-hidden="true"
                                    style={{ fontSize: "20px", color: "var(--primary)", marginRight: "20px" }}
                                ></i>

                                {/* <i class="fas fa-info-circle" aria-hidden="true"
                                style={{ fontSize: "20px",color: "var(--primary)", borderRadius: "100%", marginRight: "20px" }}
                            > </i> */}
                            </OverlayTrigger>
                        )}
                        <OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="bottom"
                            overlay={
                                <Popover style={{ padding: "10px", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)", }}>
                                    <Popover.Header as="h6">My Profile</Popover.Header>
                                    <Popover.Body style={{ padding: "10px" }}>
                                        <Row style={{ margin: 0 }}>
                                            <Col xs={6} className="text-start p-0">
                                                <b>User ID : {localStorage.getItem("userId")}</b>
                                            </Col>
                                            <Col xs={6} className="text-end p-0">
                                                <b>Org ID : {localStorage.getItem("orgId")}</b>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row style={{ margin: 0 }}>
                                            <Col xs={12} className="text-center p-0">
                                                <i
                                                    class="fas fa-user-circle"
                                                    aria-hidden="true"
                                                    style={{ fontSize: "45px", color: "#A9A9A9", marginBottom: "10px", cursor: "default" }}
                                                ></i>
                                            </Col>
                                            <Col xs={12} className="text-center p-0">
                                                Welcome, <br />
                                                <b>{localStorage.getItem("userName").replace(/['"]/g, "")}</b><br />
                                            </Col>
                                        </Row>
                                        <hr />
                                        {profileMenu.filter(profileMenuItem => !profileMenuItem?.hidden).map(profileMenuItem =>
                                            <div style={subMenuItemStyle}>
                                                <div
                                                    className={localStorage.getItem("selectedSubModule") == profileMenuItem.name || hoveringOnModule == profileMenuItem.name
                                                        ? `sidebar-div bg-${theme}` : "sidebar-div"
                                                    }
                                                    style={{
                                                        color: localStorage.getItem("selectedSubModule") == profileMenuItem.name || hoveringOnModule == profileMenuItem.name
                                                            ? "white" : fontColor,
                                                        backgroundColor: localStorage.getItem("selectedSubModule") == profileMenuItem.name || hoveringOnModule == profileMenuItem.name
                                                            ? "" : "white",
                                                    }}
                                                    onClick={() => {
                                                        localStorage.setItem("selectedModule", undefined);
                                                        localStorage.setItem("selectedSubModule", profileMenuItem.name);
                                                        navigate(profileMenuItem.url);
                                                        // if (window.innerWidth < 992) {
                                                        //     window.location.reload();
                                                        // }
                                                    }}
                                                    onMouseEnter={() => {
                                                        setHoveringOnModule(profileMenuItem.name);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHoveringOnModule();
                                                    }}
                                                >
                                                    <span className="sidebar-text px-3">{profileMenuItem.label}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                class="fas fa-user-circle"
                                aria-hidden="true"
                                style={{ fontSize: "20px", color: "#A9A9A9", marginRight: "20px" }}
                            ></i>
                        </OverlayTrigger>
                        <OverlayTrigger rootClose placement="bottom" overlay={<Tooltip>Click Here to Logout</Tooltip>}>
                            <i
                                class="fas fa-sign-out-alt"
                                aria-hidden="true"
                                style={{ fontSize: "20px", color: "#999", marginRight: "20px" }}
                                onClick={logout}
                            ></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
            </div>
        </Aux>
    )
}

export default NewNavbar