const sections = [
    {
        code: "OT",                                 // index = 0
        label: "Online Tests"
    },
    {
        code: "FB",                                 // index = 1
        label: "Feedback Survey"
    },
    {
        code: "ES",                                 // index = 2
        label: "Engagement Survey"
    },
    {
        code: "ADC",                                // index = 3
        label: "Assessment Centre"
    },
    {
        code: "BE",                                 // index = 4
        label: "Batch Evaluation"
    },
    {
        code: "GEN",                                // index = 5
        label: "Profile Features"
    },
    {
        code: "PF",                                 // index = 6
        label: "Profile"
    },
    {
        code: "BOT",                                // index = 7
        label: "Bot"
    },
    {
        code: "TL",                                 // index = 8
        label: "Translation"
    },
    {
        code: "FAQ",                                // index = 9
        label: "FAQ"
    },
    {
        code: "CF",                                 // index = 10
        label: "Custom Forms"
    },
    {
        code: "WF",                                 // index = 11
        label: "Workflow"
    },
]

const controlTypes = [
    {
        label: "CRUD",                              // index = 0
        type: "crud",
        showInDropdown: true,
    },
    {
        label: "Toggle (Enable/Disable)",           // index = 1
        type: "toggle",
        showInDropdown: true,
    },
    {
        label: "List",                              // index = 2
        type: "list",
        showInDropdown: false,
    },
]

export default { sections, controlTypes }